import React, { useEffect, useRef, useState } from 'react';
import './PoleDropdown.css'; // Import the CSS file
import PoleService from '../../services/PoleService';
import { restApiUri } from '../../config';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Loader from '../loader';

const PoleDropdown = ({ placeholder = "Select a Pole" }) => {

  const history = useHistory()

  const [isOpen, setIsOpen] = useState(false);
  const [listspole, setListspole] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPole, setCurrentPole] = useState({
    "id": 0,
    "nom": "",
    "abbove": false,

    "gcp_client": false,
    "id_pole_gcp": "",
    "createdAt": "",
    "updatedAt": "",
    "banqueId": 0
  });

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = async (event, option) => {

    setLoading(true);

    // Retrieve user data from localStorage
    const user = JSON.parse(localStorage.getItem('user'));
    const email = user?.email;

    const updatedPole = await PoleService.updatePole({
      email,
      poleIdSelected: option.pole.id
    });

    if (updatedPole) {
      const modifiedPole = Object.assign(currentPole, {});
      modifiedPole.id = option.pole.id;
      modifiedPole.nom = option.pole.nom;
      setCurrentPole(modifiedPole);
      user.pole = modifiedPole;

      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem('token', updatedPole.token)

      fetch(`${restApiUri}/api/auth/profileUser`, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${updatedPole.token}`
        }
      })
        .then(resp => resp.json())
        .then(data => {
          if (data.error) {
            console.log(data.error);
            setLoading(false);
          } else {
            localStorage.setItem('uid', data.id)
            localStorage.setItem('isLogged', '1')
            // gtag('event', 'Page View', {
            //   'user_id': data.id
            // });
            localStorage.setItem('user', JSON.stringify(data))
            // history.push('/')
            // window.location.reload();
            window.location.href = '/';
            localStorage.removeItem('to')
            setIsOpen(false);
            setLoading(false);
          }
        }).catch(e => {
          console.log(e)
          setLoading(false);
        })

    } else {
      setLoading(false);
    }
  };

  const dropdownRef = useRef(null);

   // Close the dropdown if a click occurs outside of it
   useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Fetch poles when the component mounts
  useEffect(() => {
    const fetchPoles = async () => {
      try {
        // Retrieve user data from localStorage
        const user = JSON.parse(localStorage.getItem('user'));
        const email = user?.email;

        if (email) {
          const poles = await PoleService.getAllPolesByEmail(email);

          setListspole(poles);
        } else {
          console.error('Email not found in localStorage');
        }
      } catch (error) {
        console.error('Error fetching poles:', error.message);
      }
    };

    fetchPoles();
  }, []);

  useEffect(() => {
    const currentUser = JSON.parse(localStorage.getItem('user'));
    if (currentUser) {

      const currentPole = currentUser?.pole || {
        "id": 0,
        "nom": "",
        "abbove": false,
        "gcp_client": false,
        "id_pole_gcp": "",
        "createdAt": "",
        "updatedAt": "",
        "banqueId": 0
      };

      setCurrentPole(currentPole);
    }
  }, [])

  return (
    <>
      {
        loading &&
        <Loader />
      }
      {listspole.length > 1 && (
        <div className="pole-dropdown" ref={dropdownRef}>
          {isOpen && (
            <ul className="pole-dropdown-menu">
              {listspole.map((option) => (
              <li key={option.pole.id} className="pole-dropdown-option">
                <input
                    id={`pole-${option.pole.id}`}
                    type="radio"
                    name="pole"
                    // defaultValue={option.pole.id}
                    value={option.pole.id}
                    checked={option.pole.id === currentPole.id}
                    onChange={(event) => handleSelect(event, option)}
                  />
                <label htmlFor={`pole-${option.pole.id}`}>
                  {option.pole.nom}
                </label>
              </li>
            ))}
            </ul>
          )}
          <button className="pole-dropdown-button" onClick={handleToggle}>
            {currentPole.nom}
            <span className="pole-dropdown-caret">{isOpen ? '▼' : '<'}</span>
          </button>
        </div>

      )}
    </>
  );
};

export default PoleDropdown;
